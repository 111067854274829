<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">MONTHLY LIQUIDATION - REGULAR EXPENSE</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-row dense class="mt-3">
        <v-col md="3" cols="12">
          <v-checkbox
            v-if="position === 'ADMIN'"
            v-model="is_view"
            label="Is View All"
            dense
            outlined
            @change="selected_month"
          ></v-checkbox>
          <v-select
            v-model="month_of_id"
            class="mx-2"
            :items="month_items"
            item-value="id"
            item-text="month_of"
            label="Month Of"
            required
            :rules="rules.combobox_rule"
            dense
            @change="selected_month"
            outlined
          ></v-select>
          <v-data-table
            dense
            :headers="headers"
            :items="unliquidated_vouchers"
            :height="320"
            :disable-pagination="true"
            hide-default-footer
          >
            <template v-slot:item="{ item }">
              <tr @click="activerow(item)"
                  :class="{ 'info black--text': item.id === selectedVoucherID }">
                <td>
                  {{ item.voucher_no }}
                </td>
                <td>
                  {{ formatPrice(item.amount) }}
                </td>
                <td>
                  {{ formatPrice(item.balance) }}
                </td>
                <td>
                  <v-icon v-if="!is_transfer_to && (employee_id===1)"
                          class="mr-2" color="error"
                          @click="transfer_cv_to(item.id)">
                    {{ icons.mdiTransitTransfer }}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-if="is_transfer_to"></v-progress-circular>
                  {{ item.assigned_to }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col md="9" cols="12" v-if="selectedVoucherID != -1">
          <v-row dense>
            <v-col md="3" cols="12">
              <v-select
                v-model="category_id"
                class="mx-2"
                :items="category_items"
                item-value="id"
                item-text="category"
                label="Category"
                required
                :rules="rules.combobox_rule"
                dense
                @change="selected_category"
                outlined
              ></v-select>
              <v-data-table
                dense
                :headers="headers2"
                :items="available_particulars"
                :height="320"
                :disable-pagination="true"
                hide-default-footer
              >
                <template v-slot:item="{ item }">
                  <tr
                    @click="activerow_particulars(item)"
                    :class="{ 'info black--text': item.id === selectedParticularsID }"
                  >
                    <td>
                      {{ item.particulars }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
            <v-col md="9" cols="12" dense v-if="selectedParticularsID != -1">
              <v-form ref="form" class="multi-col-validation">
                <v-row dense>
                  <v-col md="12" cols="12" v-if="can_select_particulars_of">
                    <v-select
                      class="mx-2"
                      v-model="particulars_of"
                      dense
                      label="Particulars Of"
                      :items="particulars_of_items"
                      outlined
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col md="12" cols="12" v-if="special_liquidation">
                    <v-select
                      class="mx-2"
                      v-model="particulars_of"
                      dense
                      label="Particulars Of"
                      :items="particulars_of_items"
                      item-text="particulars"
                      item-value="id"
                      outlined
                      :rules="rules.combobox_rule"
                      @change="selected_particulars"
                    ></v-select>
                  </v-col>
                  <v-col md="12" cols="12">
                    <customize-components
                      :key="this.key"
                      :description="this.description"
                      :category_id="this.category_id"
                      :particulars_id="this.particulars_of"
                      :particulars_items="this.particulars_of_items"
                      :branch_id="this.branch_id"
                      :is_liquidation="true"
                      :particulars_description_items="this.particulars_description_items"
                      v-on:on_is_ca="on_is_ca"
                      v-on:on_close_description_dialog_have_amount="on_close_description_dialog_have_amount"
                      v-on:on_maf_no="on_maf_no"
                      v-on:on_close_description_dialog="on_close_description_dialog"
                      v-if="has_description"
                    ></customize-components>
                    <v-text-field
                      v-else
                      class="mx-2"
                      v-model="description"
                      dense
                      label="Description"
                      outlined
                      :rules="rules.default_max_500_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col md="12" cols="12">
                    <v-text-field
                      class="mx-2"
                      v-model="payment_for"
                      dense
                      label="Payment For"
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col md="12" cols="12">
                    <v-text-field
                      class="mx-2"
                      v-model="credit_to"
                      dense
                      label="Credit To"
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col md="12" cols="12">
                    <v-select
                      class="mx-2"
                      v-model="branch_id"
                      dense
                      label="Branch"
                      :items="branch_items"
                      item-value="id"
                      item-text="branch_code"
                      outlined
                      :rules="rules.combobox_rule"
                      v-if="branch === null||branch === '.FLOATING'||branch === 'Empty'||branch === 'FINANCE DEPARTMENT'"
                    ></v-select>
                    <v-text-field
                      v-else
                      class="mx-2"
                      v-model="branch"
                      dense
                      label="Branch"
                      outlined
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" cols="12">
                    <v-text-field
                      class="mx-2"
                      v-model="date"
                      dense
                      label="Date"
                      outlined
                      type="date"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" cols="12">
                    <v-text-field
                      class="mx-2"
                      v-model="amount"
                      dense
                      label="Amount"
                      outlined
                      min="0"
                      type="number"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-checkbox v-model="is_no_or" label="Is No OR #" dense outlined></v-checkbox>
                  <v-col md="10" cols="12">
                    <v-text-field
                      v-if="!is_no_or"
                      class="mx-2"
                      v-model="or_no"
                      dense
                      label="OR #"
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                    <v-text-field
                      v-else
                      class="mx-2"
                      v-model="specify"
                      dense
                      label="Specify"
                      outlined
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <!--                  <v-col md="12" cols="12">-->
                  <!--                    <v-card-text class="d-flex">-->
                  <!--                      <v-layout row>-->
                  <!--                        <v-flex xs12 md12 class="mt-4">-->
                  <!--                          <vue-croppie width="100%" cover ref="croppieRef" :enableOrientation="true"-->
                  <!--                                       :mouseWheelZoom="false"-->
                  <!--                                       :boundary="{ width: 800, height: 450}"-->
                  <!--                                       :viewport="{ width:785, height:425, 'type':'square' }">-->
                  <!--                          </vue-croppie>-->
                  <!--                        </v-flex>-->
                  <!--                        <v-flex xs12 md12>-->
                  <!--                          &lt;!&ndash; upload photo &ndash;&gt;-->
                  <!--                          <div>-->
                  <!--                            <v-btn color="primary" class="me-3 mt-5"-->
                  <!--                                   @click="$refs.refInputEl.click()">-->
                  <!--                              <v-icon class="d-sm-none">-->
                  <!--                                {{ icons.mdiCloudUploadOutline }}-->
                  <!--                              </v-icon>-->
                  <!--                              <span class="d-none d-sm-block">Select Receipt Photo</span>-->
                  <!--                            </v-btn>-->

                  <!--                            <input-->
                  <!--                              ref="refInputEl"-->
                  <!--                              type="file"-->
                  <!--                              accept=".jpeg,.jpg"-->
                  <!--                              :hidden="true"-->
                  <!--                              v-on:click="$refs.refInputEl.value = ''"-->
                  <!--                              v-on:change="croppie"-->
                  <!--                            />-->

                  <!--                            <v-btn color="error" outlined class="mt-5" @click="resett()"> Reset-->
                  <!--                            </v-btn>-->
                  <!--                            <p class="text-sm mt-5">Allowed JPG or JPEG Only</p>-->
                  <!--                          </div>-->
                  <!--                        </v-flex>-->
                  <!--                      </v-layout>-->
                  <!--                    </v-card-text>-->
                  <!--                  </v-col>-->
                  <v-col cols="12" v-show="alert">
                    <v-alert color="warning" text class="mb-0">
                      <div class="d-flex align-start">
                        <v-icon color="warning">
                          {{ icons.mdiAlertOutline }}
                        </v-icon>

                        <div class="ms-3">
                          <p class="text-base font-weight-medium mb-1">
                            {{ alert_message }}
                          </p>
                        </div>
                      </div>
                    </v-alert>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-btn class="w-full" color="primary" @click="save_liquidation"
                           v-if="!saving_data">
                      Save changes
                    </v-btn>
                    <v-progress-circular
                      :size="50"
                      :width="5"
                      color="primary"
                      indeterminate
                      v-else
                    ></v-progress-circular>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-btn class="w-full" color="success" @click="returnFundDialog = true"
                           v-if="!returnFundDialog">
                      Return Fund
                    </v-btn>
                    <v-progress-circular
                      :size="50"
                      :width="5"
                      color="primary"
                      indeterminate
                      v-else
                    ></v-progress-circular>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="12" cols="12">
          <v-data-table dense :headers="headers3" :items="liquidated_items">
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.cash_voucher.voucher_no }}
                </td>
                <td>
                  {{ item.category.category }}
                </td>
                <td>
                  {{ item.branch.branch_code }}
                </td>
                <td>
                  {{ item.credit_to }}
                </td>
                <td>
                  {{ item.particulars.particulars }}
                </td>
                <td>
                  {{ item.description }}
                </td>
                <td>
                  {{ formatPrice(item.amount) }}
                </td>
                <td>
                  {{ item.or_no }}
                </td>
                <td>
                  {{ item.specify }}
                </td>
                <td class="text-center" v-if="employee_id === item.created_id">
                  <v-icon v-if="!is_deleting" class="mr-2" color="error"
                          @click="delete_data(item.id)">
                    {{ icons.mdiDelete }}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate v-else></v-progress-circular>
                </td>
                <td class="text-center">
                  <v-icon
                    v-if="!is_deleting"
                    class="mr-2"
                    color="info"
                    @click="view_receipt(item.id)"
                  >
                    {{icons.mdiEyeCircle }}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-else></v-progress-circular>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="returnFundDialog" persistent max-width="30%">
      <v-card>
        <v-card-text>
          <v-form ref="form2" class="multi-col-validation">
            <v-row dense>
              <v-col md="12" cols="12">
                <v-text-field
                  class="mx-2"
                  v-model="date_deposited"
                  dense
                  label="Date Deposited"
                  outlined
                  type="date"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col md="12" cols="12">
                <v-text-field
                  class="mx-2"
                  v-model="time_deposited"
                  dense
                  label="Time Deposited"
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col md="12" cols="12">
                <v-select
                  class="mx-2"
                  v-model="bank_id"
                  dense
                  label="Bank"
                  :items="bank_items"
                  item-value="id"
                  item-text="bank_code"
                  outlined
                  @change="selected_bank"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-col>
              <v-col md="12" cols="12">
                <v-text-field
                  class="mx-2"
                  v-model="bank_account_no"
                  dense
                  label="Account #"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field
                  class="mx-2"
                  v-model="amount"
                  dense
                  label="Amount"
                  outlined
                  min="0"
                  type="number"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col md="12" cols="12">
                <v-select
                  class="mx-2"
                  v-model="deposit_status"
                  dense
                  label="Status"
                  :items="['Excess', 'Full']"
                  outlined
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-col>
              <v-col cols="12" v-show="alert_da">
                <v-alert color="warning" text class="mb-0">
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{ alert_message_da }}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-btn color="primary" @click="save_return_fund"> Save Changes</v-btn>
      <v-btn color="error" @click="returnFundDialog = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="can_view_uploaded_cheque" persistent max-width="80%">
      <ViewUploadCheque
        :key="this.key2"
        :title="'VIEW UPLOADED RECEIPT'"
        :data_items="this.uploaded_data_items"
      ></ViewUploadCheque>
      <v-btn color="error" @click="can_view_uploaded_cheque = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="is_transfer_to" persistent max-width="50%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">ASSIGN VOUCHER TO ACCOUNTING STAFF</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-form ref="form3" class="multi-col-validation mt-4">
          <v-select
            v-model="accounting_staff_id"
            class="mx-2"
            :items="accounting_staff_items"
            item-value="id"
            item-text="name"
            label="Accounting Staff"
            required
            :rules="rules.combobox_rule"
            dense
            outlined
          ></v-select>
        </v-form>
      </v-card>
      <v-btn color="success" @click="transfer_accounting_staff">Transfer</v-btn>
      <v-btn color="error" @click="is_transfer_to = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiAccountSearch,
    mdiEyeCircle,
    mdiTransitTransfer,
    mdiDelete
  } from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import customizeComponents
    from '@/views/file_interface/create/request/request_components/CustomizeRequest'
  import ViewUploadCheque from '@/views/user_interface/memos/ViewUploadMemos'

  const initialState = () => {
    return {
      avatarImg: require('@/assets/images/misc/upload_cheque.jpg'),
      can_view_uploaded_cheque: false,
      uploaded_data_items: [],
      croppieImage: '',
      key2: 0,
      cropped: false,

      is_view: false,
      saving_data: false,
      alert: false,
      alert_message: '',
      saving_data_ret: false,
      alert_da: false,
      alert_message_da: '',

      is_deleting: false,
      special_liquidation: false,
      returnFundDialog: false,
      is_no_or: false,
      specify: '',
      or_no: '',
      selectedVoucherID: -1,
      selectedParticulars: -1,
      selectedParticularsID: -1,
      month_of_id: '',
      month_items: [],
      liquidated_items: [],
      unliquidated_vouchers: [],
      available_particulars: [],
      particulars_of_items: [],
      category_id: '',
      category_items: [],
      search: '',
      headers: [
        {text: 'Voucher #', value: 'date_of_deposit', sortable: false},
        {text: 'Amount', value: 'tin', sortable: false},
        {text: 'Balance', value: 'employee.last_name', sortable: false},
        {text: 'Assign to', value: 'employee.last_name', sortable: false},
      ],
      headers2: [{text: 'Particulars', value: 'date_of_deposit', sortable: false}],
      headers3: [
        {text: 'Voucher #', value: 'date_of_deposit', sortable: false},
        {text: 'Category', value: 'date_of_deposit', sortable: false},
        {text: 'Branch', value: 'date_of_deposit', sortable: false},
        {text: 'Credited To', value: 'date_of_deposit', sortable: false},
        {text: 'Particulars', value: 'date_of_deposit', sortable: false},
        {text: 'Description', value: 'date_of_deposit', sortable: false},
        {text: 'Amount', value: 'date_of_deposit', sortable: false},
        {text: 'OR #', value: 'date_of_deposit', sortable: false},
        {text: 'Details', value: 'date_of_deposit', sortable: false},
        {text: 'Action', value: 'date_of_deposit', sortable: false},
        {text: 'View', value: 'date_of_deposit', sortable: false},
      ],
      data: [],
      amount: '0',
      date: '',
      branch: '',
      credit_to: '',
      payment_for: '',
      particulars_of: 0,
      description: '',
      can_select_particulars_of: '',
      request_data_id: 0,
      cash_voucher_id: 0,
      branch_id: 0,
      branch_items: [],
      accounting_staff_id: '',
      accounting_staff_items: [],

      bank_account_no: '',
      bank_id: '',
      bank_items: [],
      time_deposited: '',
      date_deposited: '',
      deposit_status: '',
      has_description: false,
      key: 0,
      particulars_description_items: [],
    }
  }
  export default {
    components: {
      snackBarDialog,
      ViewUploadCheque,
      customizeComponents,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiAccountSearch,
          mdiEyeCircle,
          mdiTransitTransfer,
          mdiDelete,
        },
        is_deleting: false,
        is_transfer_to: false,
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id', 'position']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('monthly_liquidation', [
        'initialization_liquidation',
        'list_of_voucher_for_liquidation',
        'list_of_category_for_liquidation',
        'list_of_particulars_for_liquidation',
        'register_regular_liquidation',
        'list_of_encoded_liquidation',
        'delete_regular_liquidation',
        'get_uploaded_receipt',
      ]),
      ...mapActions('bank_depository', ['list_of_bank_not_confidential_for_return_only']),
      ...mapActions('request_particulars', ['request_particulars_data']),
      ...mapActions('users', ['list_of_user_by_position']),
      ...mapActions('cash_vouchers', ['transfer_accounting_staff_of_voucher']),
      croppie(e) {
        this.cropped = false
        var files = e.target.files || e.dataTransfer.files
        if (!files.length) return

        var reader = new FileReader()
        reader.onload = e => {
          this.cropped = true
          this.$refs.croppieRef.bind({
            url: e.target.result
          });
        }
        reader.readAsDataURL(files[0])
      },
      resett() {
        this.$refs.croppieRef.bind({
          url: this.avatarImg
        });
        this.$refs.refInputEl.value = ''
        this.cropped = false
        this.croppieImage = ''
        this.alert = false
      },
      async view_receipt(value) {
        this.can_view_uploaded_cheque = true
        this.key2++
        this.uploaded_data_items = []
        await this.get_uploaded_receipt({
          id: value,
        })
          .then(response => {
            this.uploaded_data_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      delete_data(value) {
        this.is_deleting = true
        this.delete_regular_liquidation({
          id: value,
        })
          .then(() => {
            this.selected_month()
            this.is_deleting = false
          })
          .catch(error => {
            console.log(error)
          })
      },
      initialize_data() {
        this.list_of_bank_not_confidential_for_return_only()
          .then(response => {
            this.bank_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
        this.initialization_liquidation()
          .then(response => {
            this.request_data_id = 0
            this.unliquidated_vouchers = []
            this.category_items = []
            this.available_particulars = []
            this.month_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      on_close_description_dialog(value) {
        this.description = value
      },
      on_maf_no(value) {
        this.maf_no = value
      },
      on_close_description_dialog_have_amount(value) {
        this.disable_amount = true
        this.amount = value
      },
      on_is_ca(value) {
        this.is_ca = value
      },
      transfer_cv_to(value) {
        this.accounting_staff_items = []
        this.list_of_user_by_position({
          position: 'ACCOUNTING',
        })
          .then(response => {
            this.accounting_staff_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
        this.selectedVoucherID = value.id
        this.is_transfer_to = true
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_month() {
        this.list_of_encoded_liquidation({
          month_of_id: this.month_of_id,
          created_id: this.employee_id,
        })
          .then(response => {
            this.liquidated_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
        this.list_of_voucher_for_liquidation({
          month_of_id: this.month_of_id,
          is_all: this.is_view,
          employee_id: this.employee_id,
        })
          .then(response => {
            this.request_data_id = 0
            this.cash_voucher_id = 0
            this.category_id = ''
            this.category_items = []
            this.available_particulars = []
            this.branch_items = []
            this.selectedVoucherID = -1
            this.selectedParticularsID = -1
            this.unliquidated_vouchers = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      activerow(value) {
        this.selectedVoucherID = value.id
        this.list_of_category_for_liquidation({
          id: value.id,
        })
          .then(response => {
            this.category_id = ''
            this.request_data_id = 0
            this.cash_voucher_id = 0
            this.available_particulars = []
            this.branch_items = []
            this.selectedParticularsID = -1
            this.category_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_category() {
        var index = this.category_items
          .map(function (x) {
            return x.id
          })
          .indexOf(this.category_id)
        if (index != -1) {
          this.branch_items = this.category_items[index].branch
        }
        this.list_of_particulars_for_liquidation({
          id: this.selectedVoucherID,
          category_id: this.category_id,
        })
          .then(response => {
            this.request_data_id = 0
            this.cash_voucher_id = 0
            this.selectedParticularsID = -1
            this.available_particulars = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      activerow_particulars(value) {
        this.selectedParticularsID = value.id
        this.branch = ''
        this.data = value.data[0]
        this.cash_voucher_id = this.data.cv_id
        this.particulars_selected = value.particulars
        this.request_data_id = this.data.id
        this.description = this.data.description
        this.payment_for = this.data.payment_for
        this.credit_to = this.data.credit_to
        this.branch_id = this.data.branch_id
        this.branch = null
        if (this.data.branch != null) {
          this.branch = this.data.branch.branch_code
        }
        this.amount = this.data.amount + ''
        this.can_select_particulars_of = false
        this.special_liquidation = false
        switch (value.particulars.split('. ')[1]) {
          case 'Chapel Fund':
            this.can_select_particulars_of = true
            this.particulars_of_items = [
              'Embalmer',
              'Driver',
              'Helper',
              'Memorial Expense',
              'Rental of Funeral Car',
              'Callers',
              'Kaagapay Grocery',
              'Fare/Bank Charges',
              'Gas-Up',
              'Water Bill',
              'Electic Bill',
              'Purchase Casket',
              'Chapel Supply/Expense',
            ]
            this.description = ''
            break
          case 'Benefits':
            this.can_select_particulars_of = true
            // this.special_liquidation = true
            this.particulars_of_items = [
              'SSS',
              'PhilHealth',
              'Pag-ibig',
            ]
            // this.description = ''
            break
          case 'Revolving Fund':
            if (this.category_id != 2) {
              this.can_select_particulars_of = true
              this.request_particulars_data({
                category_id: this.category_id,
                is_liquidation: 1,
              })
                .then(response => {
                  this.particulars_of_items = response.data[0].active
                })
                .catch(error => {
                  console.log(error)
                })
              this.description = ''
            } else {
              this.special_liquidation = true
              this.request_particulars_data({
                category_id: this.category_id,
                is_liquidation: 1,
              })
                .then(response => {
                  this.particulars_of_items = response.data[0].active
                })
                .catch(error => {
                  console.log(error)
                })
            }
            break
          case 'Poultry Fund':
            this.can_select_particulars_of = true
            this.description = ''
            this.particulars_of_items = ['Feeds', 'Vitamins', 'Vet Fee', 'Lot Maintenance', 'Others']
            break
        }
      },
      async save_liquidation() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          // let options = {
          //   type: 'base64',
          //   format: 'jpeg'
          // };
          // await this.$refs.croppieRef.result(options, output => {
          //   this.croppieImage = output;
          // });
          if (this.selectedParticularsID === -1) {
            this.alert = true
            this.alert_message = 'Please Select Particular First'
            this.saving_data = false
          } else if (this.description === 'Please Click Me!!!') {
            this.alert = true
            this.alert_message = 'Please Select Particular First'
            this.saving_data = false
          } else if (parseFloat(this.amount) > parseFloat(this.data.amount)) {
            this.alert = true
            this.alert_message = 'The amount is exceed of the balance!'
            this.saving_data = false
          }
            // else if (!this.cropped) {
            //   this.alert = true
            //   this.alert_message = 'Please Upload Receipt Photo'
            //   this.saving_data = false
          // }
          else {
            const data = new FormData()
            data.append('cash_voucher_id', this.cash_voucher_id)
            data.append('category_id', this.category_id)
            data.append('month_of_id', this.month_of_id)
            data.append('date', this.date)
            data.append('credit_to', this.credit_to)
            data.append('payment_for', this.payment_for)
            var slected_par = this.special_liquidation
              ? this.particulars_selected
              : this.particulars_selected.split('. ')[1]
            var final_particlars = slected_par
            switch (slected_par) {
              case 'Branch Expense':
              case 'Regional Expense':
                // case 'Benefits':
                final_particlars = this.description.split(' # ')[0]
                break
              case 'HO Budget':
              case 'Revolving Fund':
              case 'Chapel Fund':
              case 'Poultry Fund':
              case 'Benefits':
                final_particlars = this.special_liquidation ? this.particulars_selected : this.particulars_of
                break
            }

            data.append('particulars', final_particlars)
            data.append('amount', this.amount)
            data.append('description', this.description)
            data.append('branch_id', this.branch_id)
            data.append('request_data_id', this.request_data_id)
            data.append('is_return_fund', 0)
            data.append('bank_id', 0)
            data.append('date_deposited', null)
            data.append('time_deposited', null)
            data.append('deposit_status', null)
            data.append('is_no_or', this.is_no_or)
            data.append('or_no', this.or_no)
            data.append('specify', this.specify)
            data.append('employee_id', this.employee_id)
            // data.append('receipt_location', this.croppieImage);
            this.register_regular_liquidation(data)
              .then(() => {
                this.change_snackbar({
                  show: true,
                  color: 'success',
                  text: 'SUCCESSFULLY LIQUIDATE',
                })
                this.particulars_of = 0
                this.special_liquidation = false
                this.saving_data = false
                this.selected_category()
                this.list_of_encoded_liquidation({
                  month_of_id: this.month_of_id,
                  created_id: this.employee_id,
                }).then(response => {
                  this.liquidated_items = response.data
                })
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving_data = false
              })
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      selected_particulars(value) {
        this.has_description = false
        this.description = ''
        this.key++
        if (this.particulars_of_items.length > 0) {
          var index = this.particulars_of_items
            .map(function (x) {
              return x.id
            })
            .indexOf(value)
          if (index != -1) {
            this.particulars_selected = this.particulars_of_items[index].particulars
            this.has_description = this.particulars_of_items[index].has_description === 1
            this.particulars_description_items = this.particulars_of_items[index].data
            if (this.has_description) {
              this.description = 'Please Click Me!!!'
            }
          }
        }
      },
      selected_bank(value) {
        var index = this.bank_items
          .map(function (x) {
            return x.id
          })
          .indexOf(value)
        if (index != -1) {
          this.bank_account_no = this.bank_items[index].account_no
        }
      },
      save_return_fund() {
        this.saving_data_ret = true
        if (this.$refs.form2.validate()) {
          if (this.selectedParticularsID === -1) {
            this.alert_da = true
            this.alert_message_da = 'Please Select Particular First'
            this.saving_data_ret = false
          } else if (this.description === 'Please Click Me!!!') {
            this.alert_da = true
            this.alert_message_da = 'Please Select Particular First'
            this.saving_data_ret = false
          } else if (parseFloat(this.amount) > parseFloat(this.data.amount)) {
            this.alert = true
            this.alert_message = 'The amount is exceed of the balance!'
            this.saving_data = false
          } else {
            if (this.description === '') {
              this.description = this.data.description
            }
            const data = new FormData()
            data.append('cash_voucher_id', this.cash_voucher_id)
            data.append('category_id', this.category_id)
            data.append('month_of_id', this.month_of_id)
            data.append('date', moment().format('YYYY-MM-DD'))
            data.append('credit_to', this.credit_to)
            data.append('payment_for', this.payment_for)
            var slected_par = this.special_liquidation
              ? this.particulars_selected.split('. ')[1]
              : this.particulars_selected.split('. ')[1]
            var final_particlars = slected_par
            switch (slected_par) {
              case 'Branch Expense':
              case 'Regional Expense':
                final_particlars = this.description.split(' # ')[0]
                break
              case 'HO Budget':
              case 'Revolving Fund':
              case 'Chapel Fund':
              case 'Poultry Fund':
              case 'Benefits':
                final_particlars = slected_par;
                // final_particlars = this.special_liquidation
                //   ? this.particulars_selected.split('. ')[1]
                //   : this.particulars_of
                break
            }
            data.append('particulars', final_particlars)
            data.append('amount', this.amount)
            data.append('description', this.description)
            data.append('branch_id', this.branch_id)
            data.append('request_data_id', this.request_data_id)
            data.append('is_return_fund', 1)
            data.append('bank_id', this.bank_id)
            data.append('date_deposited', this.date_deposited)
            data.append('time_deposited', this.time_deposited)
            data.append('deposit_status', this.deposit_status)
            data.append('is_no_or', this.is_no_or)
            data.append('or_no', this.or_no)
            data.append('specify', this.specify)
            data.append('employee_id', this.employee_id)
            this.register_regular_liquidation(data)
              .then(response => {
                this.change_snackbar({
                  show: true,
                  color: 'success',
                  text: 'SUCCESSFULLY LIQUIDATE',
                })
                this.particulars_of = ''
                this.special_liquidation = false
                this.returnFundDialog = false
                this.selected_category()
                this.list_of_encoded_liquidation({
                  month_of_id: this.month_of_id,
                  created_id: this.employee_id,
                }).then(response => {
                  this.liquidated_items = response.data
                })
              })
              .catch(error => {
                this.alert_da = true
                this.alert_message_da = error
                this.saving_data_ret = false
              })
          }
        } else {
          this.alert_da = true
          this.alert_message_da = 'Please Fill up the field/s'
          this.saving_data_ret = false
        }
      },
      transfer_accounting_staff() {
        if (this.$refs.form3.validate()) {
          const data = new FormData()
          data.append('cash_voucher_id', this.selectedVoucherID)
          data.append('accounting_id', this.accounting_staff_id)
          this.transfer_accounting_staff_of_voucher(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: response.status === 200 ? 'success' : 'error',
                text: response.data,
              })
              this.particulars_of = ''
              this.special_liquidation = false
              this.returnFundDialog = false
              this.selected_category()
              this.list_of_encoded_liquidation({
                month_of_id: this.month_of_id,
                created_id: this.employee_id,
              }).then(response => {
                this.liquidated_items = response.data
              })
            })
            .catch(error => {
              this.alert_da = true
              this.alert_message_da = error
              this.saving_data_ret = false
            })
        } else {
          this.alert_da = true
          this.alert_message_da = 'Please Fill up the field/s'
          this.saving_data_ret = false
        }
      },
    },
  }
</script>
